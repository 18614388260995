* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

:root {
  /* font Size ********************************** */
  /* --step--2: 12px;
  --step--1: 16px;
  --step-0: 18px;
  --step-1: 20px;
  --step-2: 22px;
  --step-3: 24px;
  --step-4: 32px;
  --step-5: 48px; */

  /* colors *************************************** */
  --color-primary: #001f3d;
  --color-black: #000;

  --color-secondary: #1974b8;
  --color-white: #fff;
  --color-sky: #666666;
  --color-bright: #f6f6f6;

  /* spacing*************************************** */

  --space-3xs: clamp(0.25rem, calc(0.25rem + 0.02vw), 0.38rem);
  --space-2xs: clamp(0.5rem, calc(0.49rem + 0.04vw), 0.75rem);
  --space-xs: clamp(0.75rem, calc(0.74rem + 0.05vw), 1.13rem);
  --space-s: clamp(1rem, calc(0.99rem + 0.07vw), 1.5rem);
  --space-m: clamp(1.5rem, calc(1.48rem + 0.11vw), 2.25rem);
  --space-l: clamp(2rem, calc(1.97rem + 0.15vw), 3rem);
  --space-xl: clamp(3rem, calc(2.96rem + 0.22vw), 4.5rem);
  --space-2xl: clamp(4rem, calc(3.94rem + 0.29vw), 6rem);
  --space-3xl: clamp(6rem, calc(5.91rem + 0.44vw), 9rem);

  /* One-up pairs */
  --space-3xs-2xs: clamp(0.25rem, calc(0.24rem + 0.07vw), 0.75rem);
  --space-2xs-xs: clamp(0.5rem, calc(0.48rem + 0.09vw), 1.13rem);
  --space-xs-s: clamp(0.75rem, calc(0.73rem + 0.11vw), 1.5rem);
  --space-s-m: clamp(1rem, calc(0.96rem + 0.18vw), 2.25rem);
  --space-m-l: clamp(1.5rem, calc(1.46rem + 0.22vw), 3rem);
  --space-l-xl: clamp(2rem, calc(1.93rem + 0.37vw), 4.5rem);
  --space-xl-2xl: clamp(3rem, calc(2.91rem + 0.44vw), 6rem);
  --space-2xl-3xl: clamp(4rem, calc(3.85rem + 0.73vw), 9rem);

  /* Custom pairs */
  --space-s-l: clamp(1rem, calc(0.94rem + 0.29vw), 3rem);
  /* line height***************** */
  --line-xs: 1.2;

  --line-sm: 1.4;

  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --fw-800: 800;
  --fw-900: 900;
}

.para-white {
  color: var(--color-white);
  text-align: left;
}

ul li a {
  color: #d0d0cf;
  cursor: pointer;
  font-size: 19.1px;
}

a {
  cursor: pointer;
}

h1 {
  font-family: "League Spartan", sans-serif;
  font-weight: 600;
}

h2 {
  font-family: "Poppins", sans-serif;
}

h6 {
  font-family: "Poppins", sans-serif;
}

h4 {
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
  font-family: "Blinker", sans-serif;
}

p {
  font-family: "Blinker", sans-serif;
}

ul li {
  list-style-position:inside;
  font-family: "Blinker", sans-serif;
  /* list-style: none; */
}
 ul li p{
  display: contents !important;
}
button {
  font-family: "Poppins", sans-serif;
  letter-spacing: 1px;
}

.button-roles {
  padding: 8px 40px;
  border: 1px solid #d0d0cf;
  color: var(--color-secondary);
  background-color: #f5f7f9;
  border-radius: 100px;
}

ul {
  font-family: "Poppins", sans-serif;
}

.para-black {
  color: var(--color-black);
}

svg {
  color: var(--color-secondary);
}

input {
  width: 100%;
  border-radius: 8px;
  padding: 4px;
  background-color: transparent;
  border: 1px solid #e2e2e2;
}

label {
  font-family: "Blinker", sans-serif;
  font-weight: var(--fw-600);
  margin-bottom: 10px;
  font-size: 13.9px;
}

textarea {
  width: 100%;
  outline: none;
  padding: 10px;
  border-radius: 8px;
  background-color: transparent;
  border: 1px solid #e2e2e2;
}

.btn-submit {
  background-color: var(--color-secondary);
  color: var(--color-white);
  border: none;
  outline: none;
  padding: 8px;
  width: 100%;
  font-size: 18.3px;
  border-radius: 15px;
}

div#basic-navbar-nav .me-auto.navbar-nav {
  margin-right: 0 !important;
  width: 100%;
  justify-content: space-around;
}

h1 span {
  color: var(--color-secondary);
}

/* navbar******************** */

.navleft {
  width: 35%;
  display: flex;
  padding-left: 15px;
  justify-content: flex-start;
  gap: 60px;
}

.navCenter {
  width: 30%;
  text-align: center;
}

.navCenter a img {
  height: 48px;
}

.navCenter a p {
  font-size: 12px;
  line-height: 1;
  letter-spacing: 1px;
  color: #666666;
  text-align: center;
  margin-top: 2px;
}

.navRight {
  width: 35%;
  display: flex;
  padding-right: 15px;
  justify-content: flex-end;
  gap: 60px;
}

.navphone {
  display: none;
}

.navRight button {
  background-color: var(--color-black);
  color: var(--color-white);
  outline: none;
  border: 1px solid var(--color-black);
  padding: 5px 15px;
  font-size: 16px;
  border-radius: 5px;
}

nav.navbar.navbar-expand-lg.navbar-light {
  background-color: #fafafa;
  padding-top: 20px;
  position: sticky;
  min-height: 12vh;
  height: 100%;
  z-index: 999;
  top: 0;
  padding-bottom: 20px;
}

nav.navbar.navbar-expand-lg.navbar-light a {
  color: #001f3d;
  font-weight: 600;
  font-size: 18px;
}

/* home ********************************* */

/* Home Saaas*&******************** */
.home_f {
  background-color: #fafafa;
}

.home-slider div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-slider {
  padding: 100px 0;
}

.home-slider-wrapper {
  background-color: #fafafa;
  margin-top: 50px;
}

.home-slider h1 {
  margin-bottom: 40px;
  font-size: 58.9px;
  text-align: center;
}

.slick-slide img {
  width: 200px;
  margin: 0 auto;
  max-height: 100px;
  object-fit: contain;
}

.homesaas {
  display: flex;
  width: 100%;
  align-items: center;
  min-height: 88vh;
  height: 100%;
  padding: 30px 0;
}

.homesaas-vision {
  width: 50%;
}

.homesaas-vision h1 {
  font-size: 46.6px;
}

.homesaas-vision h6 {
  width: 80%;
  font-size: 17px;
  line-height: 1.6;
  margin: 20px 0;
}

.homesaas-vision h6 span {
  font-weight: 700;
}

.homesaas-vision button {
  background-color: var(--color-black);
  color: var(--color-white);
  outline: none;
  border: 1px solid var(--color-black);
  padding: 5px 15px;
  border-radius: 5px;
  font-size: 16px;
}

.homesaas-vision-image {
  display: flex;
  width: 50%;
  margin-bottom: 30px;
}

.homesaas-vision-image-left {
  width: 50%;
  background-image: url(./images/star.png);
  background-repeat: no-repeat;
  background-position: 0% 75%;
}

.homesaas-vision-image-left .img-first {
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

.homesaas-vision-image-left .img-first img {
  width: 80%;
}

.homesaas-vision-image-left .img-second {
  width: 100%;
  display: flex;
  justify-content: center;
}

.homesaas-vision-image-left .img-second img {
  width: 50%;
  border-radius: 50%;
}

.homesaas-vision-image-right {
  width: 50%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: center;
  background-image: url(./images/line.png);
  background-repeat: no-repeat;
  background-position: 5% 10%;
}

.homesaas-vision-image-right-box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.homesaas-vision-image-right-box img {
  width: 80%;
}

.homesaas-vision-image-right-subs {
  text-align: center;
}

.homesaas-vision-image-right-subs h2 {
  color: var(--color-white);
  margin-bottom: 0px;
}

.homesaas-vision-image-right-subs p {
  color: var(--color-white);
  line-height: 1.2;
}

.homesaas-vision-image-right-powers {
  text-align: center;
}

.homesaas-vision-image-right-powers p {
  line-height: 1.2;
}

/*  homecounter^^^^^^^^^^^^^^^^^^^^^^^^^^^^*/

.homeCounter {
  width: 100%;
  display: grid;
  justify-content: space-around;
  padding: 50px;
  grid-template-columns: auto auto auto auto;
}

.counter-wrapper {
  width: 160px;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-white);
  text-align: center;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.counter-wrapper p {
  color: var(--color-black);
  line-height: 1.4;
  font-size: 16px;
}

.counter-wrapper .saas {
  font-size: 55px;
  font-weight: 700;
}

.counter-wrapper .saasF {
  color: var(--color-secondary);
  font-size: 55px;
  font-weight: 700;
}

/* home joiurney&&&&&&&&&&&&&&&&& */
.home-journey {
  margin: 0 auto;
  padding: 50px 0;
  position: relative;
  text-align: center;
}

.roket {
  position: absolute;
  left: 0%;
  bottom: 40%;
}

.roket img {
  width: 100px;
}

.kite {
  position: absolute;
  right: 0%;
  bottom: 70%;
}

.kite img {
  width: 100px;
}

.home-journey h1 {
  font-size: 54px;
  width: 50%;
  margin: 0 auto;
}

.home-journey h5 {
  font-size: 20px;
  font-weight: 800;
}
.home-journey h5 span {
  color: var(--color-secondary);
}
.home-journey p {
  color: #666666;
  padding-top: 20px;
  font-size: 20px;
  width: 47ch;
  margin: 0 auto;
  margin-bottom: 0;
}

.button1 {
  background-color: var(--color-secondary);
  outline: none;
  margin-top: 40px;
  font-size: 20.5px;
  color: var(--color-white);
  padding: 8px 40px;
  border: 1px solid var(--color-secondary);
  border-radius: 30px;
}

.button1 span {
  font-weight: 700;
}

/*  home experience ***********************************/
.home-experience {
  padding: 50px 0;
  width: 100%;
  display: flex;
  position: relative;
}

.home-experience-image {
  width: 50%;
  padding-left: 20px;
  position: relative;
}

.home-experience-image .imge {
  width: 400px;
  padding: 10px;
  border: 1px solid var(--color-white);
  object-fit: cover;
  height: 580px;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px;
}

.image-details {
  background-color: #ecd046;
  width: 210px;
  padding: 8px;
  left: 40%;
  bottom: 20%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
}

.image-details-left img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.image-details-left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-details-right {
  display: flex;
  align-items: last baseline;
}

.home-experience-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: self-start;
  width: 50%;
}

.service-experience-left {
  padding-left: 60px !important;
}

.home-experience-text h1 {
  font-size: 49.5px;
  margin: 0;
}

.home-experience-text p {
  color: #666666;
  text-align: left;
  width: 45ch;
  margin-top: 20px;
  font-size: 20px;
  margin-bottom: 0;
}

.home-experience-text-box {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 30px;
  column-gap: 30px;
}

.home-experience-text-box h4 {
  font-size: 23.9px;
  font-weight: 600;
}

.home-experience-text-box div p {
  font-size: 18px;
  width: 24ch;
}

.home-experience-text button {
  font-size: 20px;
  font-weight: 600;
}

/* home Service*********************************** */
.home-service {
  padding: 50px 0;
}

.home-service h1 {
  text-align: center;
  font-size: 46.5px;
}

.home-service-wraper {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 60px;
  column-gap: 50px;
  row-gap: 110px;
}

.home-service-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}

.home-service-card img {
  border-radius: 10px;
  height: 250px;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  min-width: 390px;
  background-color: #f5f7f9;
  object-fit: contain;
}

.home-service-card h3 {
  margin-top: 20px;
  font-size: 24.2px;
}

.home-service-card p {
  color: #666666;
  text-align: center;
  font-size: 18.9px;
  width: 25ch;
}

.home-service-card button {
  background-color: var(--color-black);
  color: var(--color-white);
  outline: none;
  border: 1px solid var(--color-black);
  padding: 5px 15px;
  font-size: 16px;
  border-radius: 5px;
}

/* home testomonials ************************** */

.home-testomonials {
  width: 100%;
  padding: 100px 0;
}

.home-testomonials-left {
  width: 100%;
}

.home-testomonials-left h1 {
  font-size: 46.3px;
  text-align: center;
}

.home-testomonials-right {
  width: 55%;
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 30px;
  column-gap: 30px;
}

.home-testomonials-left p {
  color: var(--color-black);
  font-size: 20.2px;
  text-align: center;
  margin-bottom: 30px;
}

.home-testomonials-left-card {
  border: 1px solid #92909059;
  padding: 25px 40px;
  border-radius: 20px;
}

.home-testomonials-left-card-top {
  display: flex;
  margin-bottom: 30px;
}

.home-testomonials-left-card-top img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 20px;
}

.home-testomonials-left-card-top h6 {
  color: var(--color-black);
  font-size: 16.9px;
  margin-bottom: 0;
}

.home-testomonials-left-card-top p {
  color: var(--color-black);
  text-align: left;
  font-size: 12.6px;
}

.home-testomonials-left-card-bottom p {
  color: var(--color-black);
  font-size: 17.9px;
}

/* footer******************** */
footer {
  background-color: var(--color-secondary);
  padding-top: 80px;
  padding-bottom: 100px;
}

.footer-resposive {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.only-ph {
  display: none;
}

.footer {
  display: grid;
  grid-template-columns: 1fr 3.7fr;
}

.footer h4 {
  color: var(--color-white);
  font-size: 22.4px;
  line-height: 1.5;
}

.footer ul {
  color: var(--color-white);
  font-size: 25.4px;
  font-weight: 600;
}

.footer ul li {
  color: #d0d0cf;
  margin-top: 10px;
  font-weight: 500;
  list-style-type: none;
  font-size: 19.1px;
}

.icons-footer svg {
  margin-right: 10px;
  color: var(--color-white);
  font-size: 24px;
}

/* bottom bar ****************************** */
.bottom-bar-bg {
  background-color: var(--color-secondary);
}

.bottom-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid var(--color-bright);
}

.bottom-bar-left p {
  color: var(--color-white);
  text-align: left;
  font-weight: 600;
  font-size: 18px;
}

.bottom-bar-left p span {
  font-size: 26px;
  color: #d3d3d3e1;
}

.bottom-bar-right p {
  font-size: 18px;
  text-align: right;
}

.bottom-bar-right h6 {
  font-size: 15px;
  color: #d0d0cf;
  text-align: left;
}

.vc-logo {
  width: 40% !important;
}

.vc-logo {
  position: relative;
  display: inline-block;
}

.vc-logo .img-top {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}

.vc-logo:hover .img-top {
  display: inline;
}

/* media query ************************************************************************************************ */
@media only screen and (max-width: 1200px) {
  .homesaas-vision-image-left {
    background-position: 0% 80%;
  }

  .about-team {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}

@media only screen and (max-width: 991px) {
  /* our value**************** */

  .our-value-top {
    gap: 20px !important;
  }

  .our-value-bottom {
    gap: 20px !important;
    margin-top: 25px !important;
  }

  .our-value-top-box {
    max-width: 220px !important;
  }

  /* home-service *************** */
  .service-button {
    flex-direction: column !important;
  }

  .service-boutique-left {
    padding-left: 0 !important;
  }

  .service-boutique-left h6 {
    text-align: center;
  }

  .service-leader-wrapper-left {
    padding-left: 0 !important;
  }

  .spacing-bottom {
    padding-top: 30px;
  }

  .service-power {
    grid-template-columns: 1fr !important;
    justify-content: center !important;
    padding-top: 0px !important;
  }

  .service-leader-wrapper-left img {
    width: 100%;
  }

  .spacing-bottom {
    padding-bottom: 50px;
  }

  .service-power button {
    margin-top: 20px !important;
    margin-bottom: 40px !important;
  }

  .service-power-left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
    align-items: center;
  }

  .home-experience.service-experience {
    display: flex;
    padding-top: 0;
    flex-direction: column-reverse;
  }

  .service-leader {
    padding: 50px 0 !important;
  }

  .service-leader-wrapper-left button {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 20px !important;
  }

  .service-boutique {
    padding: 50px 0 !important;
  }

  .home-experience.service-experience div {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .service-experience-left {
    padding-left: 0 !important;
  }

  .service-power {
    margin: 20px;
  }

  .service-power-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .service-boutique {
    grid-template-columns: 1fr !important;
  }

  .service-boutique-left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .service-leader-wrapper {
    display: flex !important;
    flex-direction: column-reverse;
  }

  /* ********************** */
  .home-journey h1 {
    width: 100%;
  }

  .home-journey p {
    width: 75%;
  }

  .image-details {
    bottom: 50%;
  }

  /* *************** */
  .contact-tabs {
    width: 100% !important;
  }

  .contact-us {
    padding: 50px 0 !important;
  }

  .contact-us h4 {
    font-size: 20px !important;
    color: #666;
  }

  .TestomonialCard {
    grid-template-columns: 1fr !important;
    gap: 50px !important;
  }

  .testomonial-para p {
    text-align: justify;
  }

  .navphone {
    display: block !important;
  }

  .navCenter {
    display: none;
  }

  .navbar-collapse {
    background-color: #f5f7f9;
  }

  .contact-us .contact-wrapper {
    gap: 25px;
  }

  .homesaas-vision-image-left {
    background-position: -10% 80%;
    background-size: 70px;
  }

  .homesaas-vision-image-right-box img {
    width: 100%;
  }

  .home-service-wraper {
    grid-template-columns: auto;
  }

  .home-testomonials {
    display: inherit;
  }

  .home-testomonials-left {
    width: 100%;
    margin-bottom: 30px;
  }

  .home-testomonials-left br {
    display: none;
  }

  .home-testomonials-left p {
    width: 100%;
  }

  .home-testomonials-right {
    width: 100%;
  }

  .navleft {
    width: 100%;
    display: flex;
    gap: 10px;
    padding-right: 15px;
    margin-bottom: 10px;
    flex-direction: column;
    text-align: right;
    margin: 5px 0;
  }

  br {
    display: none !important;
  }

  .navRight {
    gap: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    padding-left: 15px;
    padding-bottom: 15px;
    justify-content: left;
    width: 100%;
  }

  .homesaas-vision-image-left .img-second img {
    width: 80%;
  }

  .homeCounter {
    grid-template-columns: auto auto;
    row-gap: 20px;
  }

  .footer {
    grid-template-columns: auto;
    gap: 30px;
  }

  .footer ul {
    padding: 0;
  }

  .homesaas-vision-image-left .img-second img {
    width: 70%;
  }

  .homesaas-vision-image-right-box {
    padding: 30px 15px;
  }

  /* about ************************************* */
  .about-us-wrapper {
    padding: 50px 0 !important;
  }

  .about-company-wrapper {
    display: inherit !important;
    padding: 20px;
  }

  .about-company-left {
    width: 100% !important;
  }

  .about-company-wrapper .about-company-right-top {
    width: 100% !important;
    margin-top: 30px;
  }

  .about-testo-content {
    gap: 20px !important;
  }

  /* .about-testo-content img {
    width: 100% !important;
  } */

  .about-philosophy-content {
    grid-template-columns: 1fr !important;
  }

  .about-philosophy-content div img {
    width: 100% !important;
    object-fit: contain !important;
    height: 300px !important;
  }

  .about-testo {
    padding: 50px 0 !important;
  }

  .about-philospy {
    padding: 50px 0 !important;
  }

  .about-company {
    padding: 50px 0 !important;
  }

  .navphone a {
    border-bottom: none !important;
  }

  nav.navbar.navbar-expand-lg.navbar-light a {
    border-bottom: 1px solid #00000015;
    font-size: 16px !important;
    text-align: left !important;
    font-weight: 500;
  }

  nav.navbar.navbar-expand-lg.navbar-light a img {
    width: 130px;
  }

  .navRight a:last-child {
    border-bottom: none !important;
  }

  .home-experience-image .imge {
    width: 300px;
    height: 470px;
  }

  .home-experience-image {
    padding-left: 0 !important;
  }

  .home-experience-text p {
    width: 100%;
  }

  .home-experience-text-box div p {
    width: 100%;
  }

  .image-details {
    bottom: 45%;
    left: 30%;
  }
}

@media only screen and (max-width: 767px) {
  .blog-multiple-card {
    padding: 50px 0 !important;
  }
  .blog-head {
    padding: 50px 0 !important;
  }
  .blog-card-tab {
    flex-direction: column;
  }
  .blog-multiple-card {
    grid-template-columns: 1fr !important;
  }
  .blog-multiple-card-wrapper {
    margin-bottom: 0 !important;
  }
  .blog-card-details {
    width: 100% !important;
  }
  .blog-card-details-route p {
    width: 10% !important;
  }
  .about-testo-content {
    grid-template-columns: 1fr !important;
  }

  .about-team {
    grid-template-columns: 1fr !important;
  }
  .privacy-policy ul li {
    text-align: center;
  }
  .about-testo-content .pramesh {
    align-items: center !important;
  }

  .about-testo-content .vikram {
    align-items: center !important;
  }

  /* our value**************** */
  .our-value-wrapper {
    padding: 50px 0 !important;
  }

  .our-value-top {
    display: inherit !important;
  }

  .our-value-bottom {
    display: inherit !important;
    margin-top: 0 !important;
  }

  .our-value-top-box {
    max-width: 100% !important;
    margin-bottom: 25px;
  }

  /* ****************** */
  .roket {
    display: none;
  }

  .kite {
    display: none;
  }

  .cup-box h4 {
    font-size: 14px !important;
  }

  nav.navbar.navbar-expand-lg.navbar-light {
    border-bottom: 1px solid #00000015;
  }

  .home_f {
    padding-top: 20px;
  }

  button {
    font-size: 16px !important;
  }

  ul li {
    font-size: 16px !important;
  }

  ul li a {
    font-size: 16px !important;
  }

  ul {
    font-size: 20px !important;
  }

  footer {
    padding: 50px 0;
  }

  .bottom-bar-right h6 {
    font-size: 16px !important;
  }

  .home-experience {
    display: inherit;
  }

  .home-journey h1 {
    text-align: left;
  }

  .home-journey p {
    text-align: left;
  }

  .home-experience-text-box {
    grid-template-columns: 1fr;
    gap: 30px;
  }

  .home-experience-text button {
    font-size: 18px;
    margin: 50px auto;
    margin-bottom: 0;
  }

  .home-service h1 {
    text-align: left;
  }

  .home-service-card h3 {
    font-size: 21px;
    font-weight: 600;
  }

  .home-testomonials-left-card {
    padding: 22px;
  }

  .home-testomonials-left-card-top {
    margin-bottom: 16px;
  }

  .homesaas-vision h6 {
    line-height: 1.4;
    color: #666;
  }

  .homesaas-vision button {
    margin: 0 auto;
    padding: 8px 40px;
    margin-top: 16px;
  }

  .home-testomonials {
    padding: 50px 0;
  }

  .about-us-wrapper h1 {
    font-size: 46.4px !important;
  }

  .homesaas-vision-image-left {
    width: 100%;
  }

  .homesaas-vision-image-right {
    width: 100%;
  }

  .homesaas-vision-image-right-box {
    padding: 0;
  }

  .homeCounter {
    padding-bottom: 50px;
    padding-top: 0px;
  }

  .home-service-card p {
    font-size: 16px;
  }

  .home-service-card button {
    font-size: 18px;
  }

  .counter-wrapper {
    width: 160px;
  }

  .container {
    padding-left: 22px;
    padding-right: 22px;
  }

  .image-details {
    bottom: 15%;
  }

  .form-wrapper {
    grid-template-columns: 1fr !important;
    gap: 15px !important;
  }

  .homesaas-vision-image-left {
    background-position: -10% 75%;
    background-size: 60px;
  }

  .contact-wrapper {
    gap: 20px !important;
    margin-top: 50px;
    grid-template-columns: 1fr !important;
  }

  .contact-wrapper-box {
    margin-top: 0 !important;
  }

  .contact-wrapper-box p {
    text-align: center;
  }

  .about-us .about-us-wrapper.test h1 {
    font-size: 40px !important;
  }

  .TestomonialCard-wrapper {
    padding: 25px !important;
  }

  .testomonial-rating h2 {
    font-size: 21px !important;
  }

  .testomonial-rating p {
    text-align: center !important;
    margin-bottom: 0 !important;
  }

  .rating-point span {
    font-size: 22px !important;
  }

  .testomonial-para p {
    text-align: justify !important;
  }

  .TestomonialCard-wrapper img {
    margin-bottom: 20px !important;
  }

  .testomonial-para .home-journey {
    width: 100%;
  }

  .home-experience-image {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .home-experience-text {
    width: 100%;
  }

  .home-experience-text br {
    display: none;
  }

  .homesaas-vision-image-right {
    background-position: 0% -5%;
    background-size: 80px;
  }

  .home-experience-text {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .dev-list ol {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: start !important;
  }

  .dev-list {
    display: flex !important;
    justify-content: center !important;
  }

  button {
    font-size: 14px !important;
  }

  h1 {
    font-size: 36px !important;
    text-align: center !important;
  }

  h2 {
    text-align: center !important;
  }

  h3 {
    text-align: center !important;
  }

  h4 {
    text-align: center !important;
  }

  h5 {
    text-align: center !important;
  }

  h6 {
    text-align: center !important;
  }

  p {
    font-size: 16px !important;
    width: 100% !important;
    text-align: center !important;
  }

  .home-testomonials-left-card-top p {
    text-align: left !important;
  }

  .home-testomonials-left-card-bottom p {
    text-align: left !important;
  }

  .homesaas-vision button {
    display: flex;
    padding: 8px 10px;
  }

  .about-philosophy-content div img {
    min-width: 100% !important;
  }

  .footer {
    grid-template-columns: auto;
    text-align: center;
  }

  .footer-resposive {
    grid-template-columns: 1fr 1fr;
  }

  .only-ph {
    display: block;
  }

  .footer h4 br {
    display: block !important;
  }

  .home-service h1 br {
    display: block !important;
  }

  .home-service-card img {
    min-width: 320px !important;
  }

  .about-testo-subcontent {
    width: 100%;
    border-radius: 20px;
  }

  .about-us-wrapper img {
    height: auto !important;
    object-fit: contain !important;
  }

  .about-philosophy-content div p {
    width: 100% !important;
  }

  .bottom-bar-left p {
    text-align: center;
  }

  .bottom-bar-right h6 {
    text-align: center;
  }

  .about-us-wrapper p {
    width: 100% !important;
  }

  /* .about-company-right {
    grid-template-columns: 1fr 1fr !important;
  } */
  /* .about-company-rights {
    grid-template-columns: 1fr 1fr !important;
  } */

  .about-testo-content {
    grid-template-columns: 1fr !important;
  }

  .about-testo-content img {
    height: 450px !important;
    object-fit: cover !important;
  }

  .contact-tabs-form {
    padding: 1rem !important;
  }

  .contact-wrapper-box {
    padding: 20px !important;
  }

  .react-tabs__tab-list {
    width: 80% !important;
  }

  .contact-tabs .react-tabs__tab-panel {
    padding: 1.5rem !important;
  }

  .dev-list .react-tabs__tab-panel {
    padding: 0 !important;
  }

  .homesaas-vision-image-right-box img {
    width: 80%;
  }

  .bottom-bar {
    display: inherit;
    padding: 10px;
  }

  .bottom-bar-right p {
    text-align: center;
  }

  .homesaas {
    padding: 0;
  }

  .homesaas-vision-image-left .img-second img {
    width: 60%;
  }

  .homesaas-vision-image-left {
    background-position: 0% 70%;
    background-size: 60px;
  }

  .homesaas {
    display: inherit;
  }

  .homesaas-vision {
    width: 100%;
    margin-bottom: 30px;
  }

  .home-testomonials-right {
    grid-template-columns: auto;
  }

  .homesaas-vision-image {
    width: 100%;
  }

  .homesaas-vision h6 {
    width: 95%;
  }

  .homeCounter {
    column-gap: 20px;
  }
}

/* contact ************** */
.contact-us {
  padding: 100px 0;
}

.contact-us h1 {
  text-align: center;
  font-size: 65.2px;
}

.contact-us h4 {
  text-align: center;
  font-size: 31.7px;
}

.contact-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
}

.contact-wrapper-box {
  background-color: var(--color-bright);
  border-radius: 1rem;
  margin-top: 5rem;
  display: flex;
  padding: 30px 20px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.contact-wrapper-box svg {
  font-size: 35px;
}

.contact-wrapper-box h6 {
  margin: 1rem 0;
  text-align: center;
  font-size: 28.6px;
}

.contact-wrapper-box p {
  font-size: 20.2px;
  text-align: center;
  color: #30373e;
}

.contact-wrapper-box p a {
  font-size: 20.2px;
  text-align: center;
  color: #30373e !important;
}

.contact-tabs {
  width: 80%;
  display: flex;
  justify-content: center;
}

.contact-box-wrapper {
  display: flex;
  margin-top: 5rem;
  justify-content: center;
}

.react-tabs__tab-list {
  background-color: var(--color-bright);
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  padding: 20px !important;
  font-size: 20.2px !important;
  margin-bottom: 0 !important;
  border-bottom: none !important;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 60%;
  text-align: center;
}

.contact-tabs .react-tabs__tab--selected {
  background-color: var(--color-secondary) !important;
  color: var(--color-white) !important;
}

.react-tabs__tab {
  min-height: 60px;
  display: flex !important;
  border-radius: 10px !important;
  align-items: center !important;
  border: none !important;
  justify-content: center !important;
}

.react-tabs__tab:focus:after {
  background-color: transparent !important;
}

.contact-tabs .react-tabs__tab-panel {
  background-color: var(--color-bright);
  min-width: 100%;
  border-radius: 1rem;
  padding: 5rem;
}

.center-about {
  text-align: center;
}

button.slick-arrow.slick-next {
  display: none !important;
}

button.slick-arrow.slick-prev {
  display: none !important;
}

.react-tabs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-tabs-form h2 {
  text-align: center;
  font-weight: 600;
  margin-bottom: 3rem;
  font-size: 34.6px;
}

.form-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  row-gap: 20px;
}

.contact-tabs-form {
  background-color: var(--color-white);
  padding: 2rem;
  border-radius: 1rem;
}

.form-textarea {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}

/* about us wrapper********************** */

.about-us-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 100px;
}

.about-us-wrapper p {
  width: 50ch;
  font-size: 23.2px;
  text-align: center;
}

.about-us-wrapper img {
  width: 100%;
  margin: 0 auto;
  height: 450px;
  object-fit: cover;
  border-radius: 30px;
  margin-top: 50px;
}

.about-company {
  background-color: var(--color-secondary);
  padding: 100px 0;
}

.about-company-wrapper {
  display: flex;
  gap: 50px;
}

.slick-slide {
  margin: 0 10px;
}

.slick-track {
  display: flex;
  align-items: center;
}

.about-company-left {
  display: flex;
  width: 50%;
  justify-content: center;
  flex-direction: column;
}

.about-company-right-top {
  width: 50% !important;
}

.about-company-left h1 {
  color: var(--color-white);
  font-size: 60.1px;
}

.about-company-left p {
  color: var(--color-bright);
  font-size: 20px;
  width: 80%;
}

.about-company-right {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.about-company-right-top h6 {
  margin-bottom: 15px !important;
}

.about-company-rights {
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 1fr; */
  gap: 30px;
  margin-bottom: 25px;
}

.about-company-left h1 span {
  color: #ecd046;
}

.about-company-right img {
  width: 100%;
  background-color: var(--color-white);
  padding: 10px;
  object-fit: contain;
  height: 90px;
  border-radius: 10px;
}

.about-company-rights img {
  width: 100%;
  background-color: #e5e5e5;
  padding: 10px;
  object-fit: contain;
  height: 90px;
  border-radius: 10px;
}

.about-experience {
  background-color: var(--color-bright);
}

.about-philospy {
  padding: 100px 0;
  background-color: #fafafa;
}

.about-philospy-wrapper h1 {
  text-align: center !important;
  font-size: 54.8px;
  margin-bottom: 50px;
}

.about-philosophy-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 50px;
}

.about-philosophy-content h2 {
  font-size: 31.2px;
  font-weight: 600;
}

.about-philosophy-content div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-philosophy-content div p {
  text-align: center;
  width: 60%;
  font-size: 24.9px;
}

.about-philosophy-content div img {
  background-color: #f5f7f9;
  border-radius: 10px;
  height: 250px;
  padding-left: 20px;
  padding-top: 20px;
  margin-bottom: 10px;
  padding-right: 20px;
  min-width: 390px;
  object-fit: contain;
}

/* testomonials about********************** */
.about-testo {
  padding: 100px 0;
}

.about-testo-content {
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  gap: 125px;
}

.about-testo-content img {
  height: 470px;
  width: 300px;
  border-radius: 20px;
  object-fit: cover;
  margin-bottom: 20px;
}

.about-testo-content .pramesh {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.about-testo-content .vikram {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.about-testo-content div h3 {
  color: var(--color-secondary);
  margin-bottom: 16px;
  font-size: 27.7px;
  font-weight: 600;
}

.about-testo-subcontent {
  background-color: var(--color-bright);
  padding: 10px 20px;
  width: 300px;
}

.about-testo-subcontent p {
  background-color: var(--color-white);
  padding: 5px 20px;
  font-size: 14.8px;
}

.about-testo-subcontent h6 {
  font-size: 22.1px;
}

.about-testo-subcontent p svg {
  margin-left: 16px;
  font-size: 22px;
}

/* testimonials **************** */
.TestomonialCard {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 auto;
  padding-bottom: 100px;
  gap: 200px;
}

.TestomonialCard-wrapper {
  display: flex;
  background-color: var(--color-white);
  flex-direction: column;
  align-items: center;
  padding: 50px;
  min-height: 500px;
  max-width: 100%;
  border-radius: 30px;
}

.TestomonialCard-wrapper img {
  width: 180px;
  margin-bottom: 30px;
  height: 105px;
  object-fit: contain;
}

.testomonial-rating {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testomonial-rating h2 {
  color: var(--color-secondary);
  font-size: 37.1px;
}

.testomonial-para p {
  font-size: 20.4px !important;
}

.about-us-wrapper.test h1 {
  font-size: 68.3px !important;
}

.testomonial-rating p {
  color: #8b8b8b;
  font-size: 20.1px;
}

.bg-gray {
  background-color: #fafafa;
}

.about-us-wrapper h1 {
  text-align: center;
  font-size: 91.2px;
}

/* service ************************** */
.service-button {
  width: 100%;
  display: flex;
  gap: 30px;
  margin-bottom: 20px;
  flex-direction: row;
}

.service-button .react-tabs__tab-list {
  background-color: transparent !important;
  width: 100% !important;
  gap: 20px;
}

.service-button .react-tabs__tab-panel {
  padding: 0 !important;
  background-color: #1974b8 !important;
}

.service-button .react-tabs__tab--selected {
  background-color: var(--color-secondary) !important;
  outline: none;
  font-size: 20.5px !important;
  color: var(--color-white) !important;
  border: 1px solid var(--color-secondary) !important;
  border-radius: 30px !important;
  height: 40px;
}

.service-button .react-tabs__tab {
  border: 1px solid #d0d0cf !important;
  color: var(--color-secondary);
  background-color: #f5f7f9;
  font-weight: 600 !important;
  font-size: 20px !important;
  border-radius: 100px !important;
}

.dev-list {
  margin-top: 20px;
}

.dev-list ol {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 0;
  gap: 20px;
}

.dev-list ol li {
  font-size: 18px;
  font-weight: 500;
  color: #666;
  display: flex;
  gap: 10px;
  list-style: none;
  align-items: center;
}

.dev-list ol li img {
  width: 28px;
}

.service-button button {
  margin-top: 0;
}

.home-experience-text-box img {
  margin-bottom: 20px;
}

.service-leader {
  background-color: var(--color-secondary);
  padding: 100px 0;
}

.service-leader-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 20px;
}

.service-leader-wrapper-left button {
  margin-top: 20px;
  font-weight: 600;
}

.service-leader-wrapper-left h1 {
  color: var(--color-white);
  font-size: 58px;
}

.service-leader-wrapper-left p {
  color: var(--color-white);
  font-size: 22px;
}

.service-leader-wrapper-left ul li {
  color: var(--color-bright);
  list-style-type: disc;
  margin-bottom: 12px;
  font-size: 18px;
}

.service-power {
  background-color: var(--color-secondary);
  display: grid;
  border-radius: 50px;
  grid-template-columns: 1fr 1.3fr;
  align-items: center;
}

.service-power-wrapper {
  padding-bottom: 100px;
  padding-left: 100px;
  padding-right: 100px;
}

.service-power h1 {
  color: var(--color-white);
  font-size: 58px;
}

.service-power h5 {
  color: #d9d9d9;
  font-size: 22px;
}

.service-power button {
  font-weight: 600;
}

.service-leader-wrapper-left {
  padding-left: 60px;
}

.service-leader-wrapper-left img {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}

.service-leader-wrapper-left ul {
  padding-left: 2rem !important;
}

.service-power img {
  height: 500px;
  width: 100%;
  object-fit: cover;
  display: flex;
  justify-content: center;
}

.service-power button {
  margin-top: 50px;
}

.service-boutique {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  padding: 100px 0;
}

.service-boutique-left p {
  color: var(--color-black);
  margin-bottom: 30px;
  font-size: 22px;
}

.service-boutique-left ul li {
  color: #666666;
  align-items: center;
  font-size: 18px;
  display: flex;
  margin-bottom: 10px;
}

.service-boutique-left h1 {
  font-size: 58px;
}

.service-boutique-left {
  padding-left: 60px;
}

.service-boutique-left ul li img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.service-boutique-left img {
  object-fit: cover;
  width: 100%;
}

.service-boutique-left h5 {
  color: var(--color-secondary);
  font-size: 28px;
}

.service-boutique-left button {
  font-size: 23px;
}

.cup-box {
  position: absolute;
  bottom: 14%;
  left: 18%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  width: 130px;
  border: 2px solid #666;
  padding: 15px 5px;
  border-radius: 10px;
}

.cup-box-wrapper img {
  width: 20px !important;
}

.cup-box-wrapper {
  display: grid;
  gap: 3px;
  grid-template-columns: 1fr 3.5fr;
  align-items: center;
}

.cup-box h4 {
  font-size: 18px;
}

.cup-box-wrapper p {
  font-size: 10px !important;
  margin: 0;
  font-weight: 600;
}

.hiring {
  position: relative;
}

.hiring img {
  width: 100%;
}

.about-us-wrapper.test {
  padding-bottom: 50px !important;
}

.test p {
  width: 50ch;
  font-size: 21px;
}

ul {
  margin-left: 0 !important;
  padding-left: 0 !important;
}

.home-experience.service-experience .home-experience-image {
  display: flex;
  justify-content: center;
}

/* our values********************* */
.our-value-wrapper {
  padding: 100px 0;
}

.our-value-top {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

.our-value-top-box {
  text-align: center;
  background-color: #f5f7f9;
  padding: 20px;
  max-width: 410px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  min-height: 250px;
  border: 1px solid #d0d0cf;
}

.our-value-top-box p {
  color: #666666;
  font-size: 18px;
}

.our-value-top-box img {
  width: 55px;
  margin-bottom: 16px;
}

.our-value-top-box h2 {
  font-weight: 600;
}

.our-value-bottom {
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 50px;
}

/* about- team ********************** */
.about-team {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  padding: 50px 0;
  gap: 20px;
}

.about-team-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  text-align: center;
  align-items: center;
}

.about-team-wrapper a {
  font-size: 30px;
}

.about-team-wrapper img {
  height: 300px;
  object-fit: cover;
  border-radius: 100%;
}
.privacy-policy {
  padding: 50px 0;
}

/* blog page ********************** */
.blog-head {
  padding: 100px 0;
  text-align: center;
}

.blog-card-tab {
  display: flex;
  justify-content: space-evenly;
}
.blog-card-tab button {
  border: none;
  outline: none;
  background-color: transparent;
  padding: 10px;
  font-size: 21px;
}

.blog-multiple-card {
  display: grid;
  padding: 100px 0;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}
.blog-multiple-card-wrapper-img img {
  width: 100%;
  max-height: 350px;
  transition: transform 0.2s;
  object-fit: cover;
}
.blog-multiple-card-wrapper-img img:hover {
  transform: scale(1.04);
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
}
.blog-multiple-card-wrapper-content {
  margin-top: 70px;
}
.blog-multiple-card-wrapper-content p {
  font-size: 1rem;
  line-height: 1.5;
}
.blog-multiple-card-wrapper-content h6 {
  font-size: 18px;
}
.blog-multiple-card-wrapper-content h3 {
  font-size: 29px;
  color: var(--color-black);
  font-weight: 600;
  line-height: 1.3;
}

.blog-multiple-card-wrapper-content div {
  display: grid;
  grid-template-columns: 10fr 1fr;
  align-items: center;
  cursor: pointer;
}
.blog-multiple-card-wrapper-content div span svg {
  font-size: 28px;
  transition: transform 0.5s;
}
.blog-multiple-card-wrapper-content div span svg:hover {
  transform: rotate(-45deg);
}
.blog-multiple-card-wrapper {
  margin-bottom: 100px;
}

/* blog card details********************** */
.blog-card-details {
  width: 80ch;
  margin: 0 auto;
}
.blog-card-details-route {
  display: flex;
  padding-top: 100px;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
}
.blog-card-details-route p {
  margin-bottom: 0 !important;
  font-size: 20px;
}
.blog-card-details-route h6 {
  margin: 0 !important;
  font-size: 20px;
}

.blog-card-details-content img {
  width: 100%;
  max-height: 420px;
  height: 100%;
  object-fit: cover;
  margin-bottom: 50px;
}

.blog-button {
  border: none;
  border-radius: 50px;
  outline: none;
  background-color: #1974b8;
  padding: 10px 15px;
  font-size: 16px;
  margin-top: 20px;
  color: #fff;
}
.bloglistcategory {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  width: 100%;
}

.error {
  color: rgba(255, 0, 0, 0.877);
  font-size: 12px;
}

.capital {
  text-transform: capitalize;
}
.card-button-blog {
  margin-bottom: 100px;
}
